<template>
  <v-card class="px-3 px-md-6 py-6 custom-scroll">
    <v-data-table
      fixed-header
      :headers="headers"
      :items="tableData"
      :loading="loading"
      hide-default-footer
      class="custom-table-height cashOuts-table"
      :class="{ 'no-data-wrapper-height': !tableData.length }"
    >
      <template v-slot:header.localFiatCurrency="{ header }">
        <span>{{ header.text }}</span>
        <v-tooltip
          v-model="showCryptoTooltip"
          top
          color="white"
          content-class="transactions-table-tooltip"
          class="transactions-table-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="16"
              color="transparent"
              class="ms-1"
              v-bind="attrs"
              v-on="on"
              >$info
            </v-icon>
          </template>
          <span class="font-regular-12 gray8--text">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionsTable.cryptoAmountTooltipDesc"
              )
            }}
          </span>
        </v-tooltip>
      </template>

      <!--      <template v-slot:header.cryptoAmount="{ header }">-->
      <!--        <span>{{ header.text }}</span>-->
      <!--        <v-tooltip-->
      <!--          v-model="showUsdTooltip"-->
      <!--          top-->
      <!--          color="white"-->
      <!--          content-class="transactions-table-tooltip"-->
      <!--          class="transactions-table-tooltip"-->
      <!--        >-->
      <!--          <template v-slot:activator="{ on, attrs }">-->
      <!--            <v-icon-->
      <!--              size="16"-->
      <!--              color="transparent"-->
      <!--              class="ms-1"-->
      <!--              v-bind="attrs"-->
      <!--              v-on="on"-->
      <!--              >$info-->
      <!--            </v-icon>-->
      <!--          </template>-->
      <!--          <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--            {{-->
      <!--              $t(-->
      <!--                "panel.transactionsPage.depositTransactions.depositTransactionsTable.usdValueTooltipDesc"-->
      <!--              )-->
      <!--            }}-->
      <!--          </span>-->
      <!--        </v-tooltip>-->
      <!--      </template>-->

      <!--/////////////////////////////////////////////////////////////////////////////////-->

      <!--      <template v-slot:header="{ props: { headers } }">-->
      <!--        <thead>-->
      <!--          <tr>-->
      <!--            <th v-for="item in headers" :key="item.value">-->
      <!--              <span class="font-regular-12 gray9&#45;&#45;text"> {{ item.text }} </span>-->

      <!--              <v-tooltip-->
      <!--                v-if="item.value === 'cryptoAmount'"-->
      <!--                v-model="showCryptoTooltip"-->
      <!--                top-->
      <!--                color="white"-->
      <!--                content-class="transactions-table-tooltip"-->
      <!--                class="transactions-table-tooltip"-->
      <!--              >-->
      <!--                <template v-slot:activator="{ on, attrs }">-->
      <!--<v-icon-->
      <!--                    size="16"-->
      <!--                    color="transparent"-->
      <!--                    v-bind="attrs"-->
      <!--                    v-on="on"-->
      <!--                    >$info-->
      <!--                  </v-icon>-->
      <!--                </template>-->
      <!--                <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--                  {{-->
      <!--                    $t(-->
      <!--                      "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.cryptoAmountTooltipDesc"-->
      <!--                    )-->
      <!--                  }}-->
      <!--                </span>-->
      <!--              </v-tooltip>-->

      <!--              <v-tooltip-->
      <!--                v-if="item.value === 'localFiatCurrency'"-->
      <!--                v-model="showUsdTooltip"-->
      <!--                top-->
      <!--                color="white"-->
      <!--                content-class="transactions-table-tooltip"-->
      <!--                class="transactions-table-tooltip"-->
      <!--              >-->
      <!--                <template v-slot:activator="{ on, attrs }">-->
      <!--                  <v-icon size="16" color="transparent" v-bind="attrs" v-on="on"-->
      <!--                    >$info-->
      <!--                  </v-icon>-->
      <!--                </template>-->
      <!--                <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--                  {{-->
      <!--                    $t(-->
      <!--                      "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.usdValueTooltipDesc"-->
      <!--                    )-->
      <!--                  }}-->
      <!--                </span>-->
      <!--              </v-tooltip>-->
      <!--            </th>-->
      <!--          </tr>-->
      <!--        </thead>-->
      <!--      </template>-->

      <template v-slot:item.transactionId="{ item }">
        <span class="font-regular-12">{{ item.transactionId }}</span>
      </template>

      <template v-slot:item.transactionTime="{ item }">
        <span class="font-regular-12 text-no-wrap">{{
          item.transactionTime | $renderDate
        }}</span>
      </template>

      <template v-slot:item.cryptoAmount="{ item }">
        <span class="font-regular-12 me-1">{{ item.cryptoAmountString }}</span>
        <span class="font-regular-12">{{ item.cryptoName }}</span>
      </template>

      <template v-slot:item.localFiatCurrency="{ item }">
        <span class="font-regular-12">${{ item.localFiatCurrencyString }}</span>
      </template>

      <template v-slot:item.transactionStatus="{ item }">
        <v-chip
          small
          :color="item.colors.bg_color"
          :text-color="item.colors.text_color"
        >
          <span class="font-regular-10">{{ item.transactionStatus }}</span>
        </v-chip>
      </template>

      <template v-slot:no-results>
        <span class="gray7--text">
          {{
            $t(
              "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.noResult"
            )
          }}
        </span>
      </template>

      <template v-slot:no-data>
        <v-icon size="62" color="gray5" class="mb-3 d-block mx-auto"
          >$itemInPage</v-icon
        >

        <span class="gray7--text">
          {{
            $t(
              "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.noData"
            )
          }}
        </span>
      </template>

      <template v-slot:item.action="{ item }">
        <router-link
          :to="`/panel/transactions/withdrawal-details/${item.transactionId}`"
        >
          <span class="font-semiBold-12">
            {{
              $t(
                "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.details"
              )
            }}
          </span>
        </router-link>
      </template>

      <template v-slot:footer>
        <div v-if="totalPages > 0" class="d-flex justify-end mt-5">
          <v-pagination
            v-model="filters.page"
            :length="totalPages"
            :total-visible="7"
            color="gray2"
            :next-icon="nextIcon"
            :prev-icon="prevIcon"
            @input="updateQuery"
          >
          </v-pagination>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ReportService } from "@/services";
import renderDate from "@/mixins/renderDate";

export default {
  name: "withdrawal-transactions-table",
  mixins: [renderDate],
  components: {},
  props: [],
  data() {
    return {
      nextIcon: "Next >",
      prevIcon: "< Back",
      filters: {
        page: 1,
        size: 10,
      },
      loading: false,
      page: 1,
      showCryptoTooltip: false,
      showUsdTooltip: false,
      headers: [
        {
          text: this.$t(
            "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.transactionId"
          ),
          align: "start",
          sortable: false,
          value: "transactionId",
        },
        {
          text: this.$t(
            "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.dateTime"
          ),
          align: "start",
          sortable: false,
          value: "transactionTime",
        },
        {
          text: this.$t(
            "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.cryptoAmount"
          ),
          align: "start",
          sortable: false,
          value: "cryptoAmount",
        },
        {
          text: this.$t(
            "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.usdValue"
          ),
          align: "start",
          sortable: false,
          value: "localFiatCurrency",
        },
        {
          text: this.$t(
            "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.status"
          ),
          align: "start",
          sortable: false,
          value: "transactionStatus",
        },
        {
          text: this.$t(
            "panel.transactionsPage.withdrawalTransactions.withdrawalTransactionsTable.action"
          ),
          align: "start",
          sortable: false,
          value: "action",
        },
      ],
      tableData: [],
      totalPages: 0,
    };
  },
  computed: {},
  mounted() {
    this.setFiltersFromQuery();
    this.updateQuery();
  },
  methods: {
    setFiltersFromQuery() {
      Object.entries(this.$route.query).forEach(([key, val]) => {
        if (key === "page" || key === "size") {
          this.filters[key] = +val;
        } else this.filters[key] = val;
      });
    },
    updateQuery() {
      const queryParams = {};
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value) {
          queryParams[key] = value;
        }
      });
      this.$router.replace({ query: { ...queryParams } }, () => {});
      this.getCashOuts(queryParams);
    },
    getCashOuts(filters) {
      this.loading = true;
      ReportService.getWithdrawTransactions(filters)
        .then((res) => {
          this.tableData = res.data.data.content;
          this.totalPages = res.data.data.totalPages;
          if (this.tableData.length) {
            this.tableData.map((elem) => {
              elem.colors = this.getStatusColors(elem.transactionStatus);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusColors(status) {
      const data = this.$t("panel.stateStatuses").find(
        (elem) => elem.status === status
      );
      if (data) return data;
      else
        return this.$t("panel.stateStatuses").find(
          (elem) => elem.status === "OTHER"
        );
    },
  },
};
</script>

<style scoped lang="scss">
@import "WithdrawalTransactionsTable";
</style>
